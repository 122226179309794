import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setTitle, selectTitle } from 'ducks/title';
import { useEffectOnce } from 'utils/hooks';

import styles from './HelloWorld.scss';

export const HelloWorldBase = ({ title }) => (
    <>
        <h1 className={styles.title}>{title}</h1>
    </>
);

HelloWorldBase.propTypes = {
    title: PropTypes.string.isRequired,
};

const HelloWorld = () => {
    const dispatch = useDispatch();

    useEffectOnce(() => {
        dispatch(setTitle('Web ota application'));
        return true;
    }, []);

    const title = useSelector(selectTitle);

    return <HelloWorldBase title={title} />;
};

export default HelloWorld;
